<template>
    <router-link class="logo-link" v-if="to" :to="to">
        <img class="logo" :src="src" :alt="alt" />
    </router-link>
    <img v-else class="logo" :src="src" :alt="alt" />
</template>

<script>
export default {
    props: {
        to: String,
        src: String,
        alt: String
    }
}
</script>

<style lang="scss" scoped>
.logo-link {
    display: inline-block;
}

.logo {
    width: 50px;
}
</style>

