import gql from 'graphql-tag'

export const plan = gql`
  query plan($language: I18NLocaleCode) {
    plan(locale: $language) {
      data {
        id
        attributes {
          fullWidthImage {
            title
            description
            img {
              data {
                attributes {
                  name
                  url
                }
              }
            }
          }
          priceTiles {
            titles {
              title
              description
            }
            feature_tiles {
              data {
                id
                attributes {
                  title
                  subTitle
                  price
                  subPrice
                  hook
                  icon {
                    data {
                      attributes {
                        name
                        url
                      }
                    }
                  }
                  button {
                    ... on ComponentMicroLink {
                      label
                      url
                      style
                    }
                  }
                  feature_categories {
                    data {
                      id
                      attributes {
                        order
                        label
                      }
                    }
                  }
                }
              }
            } 
          }
          featuresTable {
            titles {
              title
              description
            }
            feature_categories(sort: "order:asc") {
              data {
                id
                attributes {
                  order
                  label
                  description
                  features(sort: "order:asc") {
                    data {
                      id
                      attributes {
                        order
                        label
                        description
                        advanced
                      }
                    }
                  }
                }
              }
            }
            feature_tiles {
              data {
                id
                attributes {
                  title
                }
              }
            }
          }
          questions {
            titles {
              title
              description
            }
            items(sort: "order:asc") {
              data {
                id
                attributes {
                  order
                  category
                  icon {
                      data {
                          attributes {
                              name
                              url
                          }
                      }
                  }
                  questions(sort: "order:asc") {
                    data {
                      id
                      attributes {
                        question
                        answer
                      }
                    }
                  }
                }
              }
            } 
          }
          rotatingWords {
            titles {
              title
              description
            }
            content
            buttons {
              ... on ComponentMicroLink {
                label
                url
                style
              }
            }
          }
        }
      }
    }
  }
`


