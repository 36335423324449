<template>
    <router-link
        v-if="to"
        v-bind:class="style"
        :to="to"
    >
        {{ label }}
    </router-link>
    <a
        v-else
        @click.prevent="action"
        v-bind:class="style"
        :data-id="id"
        href="#"
    >
        {{ label }}
    </a>
</template>

<script>
export default {
    props: {
        action: Function,
        to: String,
        label: String,
        style: String,
        id: String
    }
}
</script>

<style lang="scss" scoped>
a {
    color: inherit;
    text-decoration: none;
}

a.link {}

a.bold_link {
    font-weight: 800;
}

a.primary_button {
    color: var(--color-lightest);
    background-color: var(--color-darker);
}

a.secondary_button {
    color: var(--color-darkest);
    background-color: var(--color-lightest);
}

a.primary_button:hover {
    background-color: var(--color-darkest);
}

a.secondary_button:hover {
    background-color: var(--color-lighter);
}

a.primary_button,
a.secondary_button {
    font-weight: 800;
    padding: 10px 15px;
    letter-spacing: 1px;
    border-radius: $border-radius;
}

a.primary_button:hover,
a.secondary_button:hover {
    transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    -webkit-transition: background-color 200ms linear;
}

a.primary_button:active,
a.secondary_button:active {
    transform: scale(0.98);
    box-shadow: var(--box-shadow-dark);
}
</style>

