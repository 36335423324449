<template>
  <div class="home">
    <!-- <div v-if="$apollo.loading">Loading...</div>
    <div v-else>{{ data.background_image.title }}</div>
    
    maybe unstructure all data to disctinc var for components...
    -->

    <BackgroundVideoParallax
      class="large left"
      :data="data?.background_image"
    />
    <FeatureImage
      :isImgLeft="true"
      :data="data?.side_image"
    />
    <FeatureImage
      :isImgLeft="false"
      :data="data?.side_image"
    />
    <Reassurances :data="data?.reassurances" />
    <Testimonials :data="data?.testimonials" />
    <RotatingWords
      class="bg-dark"
      :data="data?.rotatingWords"
    />
  </div>
</template>

<script>
import { apollo } from '@/mixins'

import BackgroundVideoParallax from '@/components/Images/BackgroundVideoParallax.vue'
import FeatureImage from '@/components/Images/FeatureImage.vue'
import Reassurances from '@/components/Images/Reassurances.vue'
import Testimonials from '@/components/Images/Testimonials.vue'
import RotatingWords from '@/components/Text/RotatingWords.vue'

export default {
  name: 'HomeView',
  mixins: [apollo],
  components: {
    BackgroundVideoParallax,
    FeatureImage,
    Reassurances,
    Testimonials,
    RotatingWords
  },
  data() {
    return {
      apolloId: 'homepage'
    }
  }
}
</script>

<style lang="scss">
.background-image-parallax {
  color: white;
}

.reassurances .reassurance:nth-child(1) img {
  -webkit-animation: line 4s linear infinite;
  -moz-animation: line 4s linear infinite;
  animation: line 4s linear infinite;
}

@-moz-keyframes line {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes line {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes line {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-50%);
  }

  50% {
    transform: translateX(0%);
  }

  75% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.reassurances .reassurance:nth-child(2) img {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.reassurances .reassurance:nth-child(3) img {
  -webkit-animation: bouncing 4s linear infinite;
  -moz-animation: bouncing 4s linear infinite;
  animation: bouncing 4s linear infinite;
}

@-moz-keyframes bouncing {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(20%, 20%);
  }

  40% {
    transform: translate(-20%, 20%);
  }

  50% {
    transform: translate(0, 0);
  }

  60% {
    transform: translate(20%, -20%);
  }

  80% {
    transform: translate(-20%, -20%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes bouncing {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(20%, 20%);
  }

  40% {
    transform: translate(-20%, 20%);
  }

  50% {
    transform: translate(0, 0);
  }

  60% {
    transform: translate(20%, -20%);
  }

  80% {
    transform: translate(-20%, -20%);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes bouncing {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(20%, 20%);
  }

  40% {
    transform: translate(-20%, 20%);
  }

  50% {
    transform: translate(0, 0);
  }

  60% {
    transform: translate(20%, -20%);
  }

  80% {
    transform: translate(-20%, -20%);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>