<template>
    <div id="menu-mobile">
        <IconCross @click.prevent="toggleMenu('close')" />
        <Logo to="/" :src="logo?.image?.data?.attributes?.url" alt="bwop" />
        <ul>
            <li v-for="menu in menus">
                <Link :to="menu.url" :label="menu.label" :style="menu.style" />
            </li>
        </ul>
        <div>
            <Link v-for="button in buttons" :to="button.url" :label="button.label" :style="button.style" />
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logos/Logo.vue'
import Link from '@/components/Links/Link.vue'
import IconCross from '@/components/Icons/Cross.vue'

export default {
    components: {
        Logo,
        Link,
        IconCross
    },
    props: {
        logo: Object,
        menus: Object,
        buttons: Object,
        toggleMenu: Function
    }
}
</script>

<style lang="scss">
#menu-mobile {
    z-index: 1000;
    background-color: var(--color-lightest);
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    .close {
        top: 10px;
        font-size: 28px;
    }

    ul {
        text-align: left;
        list-style-type: none;
        padding-inline-start: 0px;

        li {
            margin: 20px 0px;
        }
    }

    >div a {
        display: block;
        margin: 20px 0px;
    }
}

@media (min-width: $mq-small) {
    #menu-mobile {
        left: 30%;
    }
}
</style>