<template>
    <div id="menu-desktop">
        <ul class="animate-underline">
            <li v-for="menu in menus">
                <Link :to="menu.url" :label="menu.label" :style="menu.style" />
            </li>
        </ul>
        <div>
            <Link v-for="button in buttons" :to="button.url" :label="button.label" :style="button.style" />
        </div>
    </div>
</template>

<script>
import Logo from '@/components/Logos/Logo.vue'
import Link from '@/components/Links/Link.vue'

export default {
    components: {
        Logo,
        Link
    },
    props: {
        logo: Object,
        menus: Object,
        buttons: Object
    }
}
</script>

<style lang="scss" scoped>
#menu-desktop {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    ul {
        flex: 1;
        display: flex;
        align-items: center;
        list-style-type: none;
        justify-content: flex-start;
        color: var(--color-dark);

        li {
            margin: 10px;
            display: inline;
        }

        a.router-link-active {
            color: var(--color-darkest);
        }
    }

    >div {
        display: flex;
        align-items: center;

        >a {
            display: inline;
        }

        a+a {
            margin: $margin-element;
        }
    }
}
</style>