import gql from 'graphql-tag'

export const how = gql`
    query how($language: I18NLocaleCode) {
      how(locale: $language) {
        data {
          id
          attributes {
            fullWidthImage {
              title
              description
              img {
                data {
                  attributes {
                    name
                    url
                  }
                }
              }
            }
            sideImages {
                img {
                    data {
                        attributes {
                            name
                            url
                        }
                    }
                }
                title
                description
                buttons {
                    ... on ComponentMicroLink {
                        label
                        url
                        style
                    }
                }
            }
            rotatingWords {
              titles {
                title
                description
              }
              content
              buttons {
                ... on ComponentMicroLink {
                  label
                  url
                  style
                }
              }
            }
          }
        }
      }
    }
`
