<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <router-link
    class="ff-bzioup bzioup-text"
    v-if="to"
    :to="to"
  >
    Bzioup.
  </router-link>
  <div
    v-else
    class="ff-bzioup bzioup-text"
  >Bzioup.</div>
</template>

<script>
export default {
  props: {
    to: String,
    src: String,
    alt: String
  }
}
</script>

<style
  lang="scss"
  scoped
>
.bzioup-text {
  font-size: 30px;
}
</style>
