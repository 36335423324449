<template>
    <div v-if="content && isBannerOpen" class="banner vm">
        <IconCross @click.prevent="isBannerOpen = false" />
        <p v-html="content"></p>
    </div>
</template>

<script>
import IconCross from '@/components/Icons/Cross.vue'

export default {
    components: {
        IconCross
    },
    props: {
        content: String
    },
    data() {
        return {
            isBannerOpen: true
        }
    }
}
</script>

<style lang="scss">
.banner {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    display: flex;
    font-size: 14px;
    position: fixed;
    text-align: center;
    height: $banner-height;
    box-sizing: border-box;
    padding: 5px 40px 5px 10px;
    background-color: var(--color-lighter);

    .close {
        font-size: 22px;
    }
}

@media (min-width: $mq-small) {
    .banner {
        font-size: 16px;
        padding: 5px 10px 5px 10px;
    }
}
</style>
