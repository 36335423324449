<template>
    <div
        ref="bgImgParallax"
        class="background-image-parallax vm"
        v-bind:style="[data?.img?.data?.attributes?.url
            ? {
                'background-image': `url(${data?.img?.data?.attributes?.url})`,
                'background-position': `${mouseX}px ${mouseY}px`,
            }
            : ''
        ]"
        :class="{ enter: isMouseEnter }"
        @mouseenter="listenMouseEnter"
        @mouseleave="listenMouseLeave"
    >
        <Teaser
            class="left"
            :title="data?.title"
            :description="data?.description"
            :buttons="data?.buttons"
        />
    </div>
</template>

<script>
// import { debounce } from '@/helpers'

import Teaser from '@/components/Text/Teaser.vue'

export default {
    components: {
        Teaser
    },
    props: {
        data: Object
    },
    data() {
        return {
            isMouseEnter: false,
            mouseX: -100,
            mouseY: -100,
            force: 30,
            el: null
        }
    },
    mounted() {
        this.el = this.$refs['bgImgParallax']

        if (true && this.el) {
            this.el.addEventListener('mousemove', this.listenMouseMove, true)
        }
    },
    methods: {
        listenMouseMove(e) {
            this.mouseX = ((e.clientX / window.innerWidth) * this.force) - 100
            this.mouseY = ((e.clientY / window.innerHeight) * this.force) - 100
        },
        listenMouseEnter() {
            this.isMouseEnter = true
            window.setTimeout(() => {
                this.listenMouseLeave()
            }, 50)
        },
        listenMouseLeave() {
            this.isMouseEnter = false
        }
    },
    unmounted() {
        if (this.el) this.el.removeEventListener('mousemove', this.listenMouseMove)
    }
}
</script>

<style
    lang="scss"
    scoped
>
.large {
    min-height: 500px;
}

.normal {
    min-height: 300px;
}

.thin {
    min-height: 100px;
}

.background-image-parallax {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    transition: background-position 0ms linear;
    -ms-transition: background-position 0ms linear;
    -webkit-transition: background-position 0ms linear;
}

.background-image-parallax.enter {
    transition: background-position 50ms ease-out;
    -ms-transition: background-position 50ms ease-out;
    -webkit-transition: background-position 50ms ease-out;
}

@media (min-width: $mq-medium) {
    .background-image-parallax {
        background-size: 120% 120%; // was cover
    }
}
</style>
