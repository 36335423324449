import Modal from '@/components/Text/Popup.vue'

export const modals = {
    components: {
        Modal
    },
    data() {
        return {
            isModalVisible: {}
        }
    },
    methods: {
        getModal(i) {
            return isModalVisible[i]
        },
        showModal(i) {
            this.isModalVisible[i] = true
        },
        closeModal(i) {
            this.isModalVisible[i] = false
        }
    }
}
