<template>
  <div class="beta">
    <!-- <div v-if="$apollo.loading">Loading...</div>
    <div v-else>{{ data.background_image.title }}</div>
    
    maybe unstructure all data to disctinc var for components...
    -->

    <BackgroundVideo
      class="large left"
      :data="data"
    />
  </div>
</template>

<script>
import { apollo } from '@/mixins'

import BackgroundVideo from '@/components/Images/BackgroundVideo.vue'

export default {
  name: 'BetaView',
  mixins: [apollo],
  components: {
    BackgroundVideo,
  },
  data() {
    return {
      apolloId: 'beta'
    }
  }
}
</script>

<style lang="scss">
.beta h1 {
  font-weight: 400;
  font-style: normal;
  font-family: "Sniglet", system-ui;
} 
</style>