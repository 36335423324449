<template>
    <ul class="footer-menu">
        <li v-for="link in section?.Links">
            <Link :to="link.url" :label="link.label" :style="link.style" />
        </li>
    </ul>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
    components: {
        Link
    },
    props: {
        section: Object,
        showTitle: Boolean
    }
}
</script>

<style lang="scss" scoped>
ul.footer-menu {
    list-style-type: none;
    padding-inline-start: 0px;

    li {
        text-align: left;
    }
}
</style>