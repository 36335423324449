<template>
    <div
        class="testimonials"
        v-if="data?.items?.data?.length"
    >
        <div>
            <Title
                :title="data?.title"
                :description="data?.description"
            />
            <Carousel
                :autoPlay="false"
                :pagination="true"
                :draggable="true"
                :autoPlayTime="1000"
                :numberOfItems="data.items.data.length"
                :numberOfItemsToShow="3"
                :numberOfItemsToShowCustom="[[1, 1], [559, 2], [879, 3]]"
            >
                <Slide
                    v-for="item in data.items.data"
                    v-bind:key="item?.id"
                >
                    <img
                        v-if="item?.attributes?.image?.data?.attributes?.url"
                        :src="item?.attributes?.image?.data?.attributes?.url"
                        alt=""
                    />
                    <div class="blockquote">
                        <div
                            v-if="item?.attributes?.quote"
                            class="quote"
                        > {{ item?.attributes?.quote }} </div>
                        <div
                            v-if="item?.attributes?.author"
                            class="quote-by"
                        > {{ item?.attributes?.author }} </div>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Text/Title.vue'
import Slide from '@/components/Noice/Slide.vue'
import Carousel from '@/components/Noice/Carousel.vue'

export default {
    components: {
        Title,
        Slide,
        Carousel
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.blockquote {
    white-space: break-spaces;
}

.testimonials {
    .carousel {
        padding: 20px 10px;
        margin: 20px;
    }

    img {
        width: 160px;
        height: 160px;
        border-radius: 100px;
        filter: grayscale(100%);
        -webkit-filter: grayscale(100%);
    }

    .blockquote {
        max-width: 300px;
        margin: 40px auto 0 auto;
        color: var(--color-darkest);

        .quote {
            font-size: 14pt;
            margin-top: 15px;
            padding: 0 10px;
            font-weight: bold;
            position: relative;
            font-family: cursive; // 'PT Serif', Cambria, 'Hoefler Text', Utopia, 'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
            letter-spacing: 1px;
        }

        .quote:before {
            top: 0;
            left: 0;
            opacity: 0.3;
            content: "“";
            font-size: 100px;
            line-height: 0px;
            position: absolute;
            font-family: cursive;
            color: var(--color-darker);
        }

        // .quote:after {
        //   content: "”";
        //   font-size: 24px;
        //   margin-left: 5px;
        //   font-family: cursive;
        // }
        .quote-by {
            color: var(--color-darker);
            padding: 0;
            font-size: 12pt;
            margin-top: 25px;
            text-align: right;
            font-style: italic;
        }

        .quote-by:before {
            content: '— ';
        }
    }
}
</style>