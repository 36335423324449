<template>
    <transition name="modal-fade">
        <div class="modal-overlay">
            <div class="modal" role="dialog" :aria-labelledby="ariaLabelledby" :aria-describedby="ariaDescribedby">
                <button type="button" class="modal-close" @click="close" aria-label="Close modal">
                    x
                </button>

                <div>
                    <h2>
                        <slot name="header" />
                    </h2>

                    <div>
                        <slot name="body" />
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                        </slot>

                        <!-- <PrimaryButton
                        :action="close"
                        text="Close"
                    /> -->
                    </div>
                </div>
            </div>
        </div>
    </transition>

    <!--
    
    TO USE:
        
    <Modal v-show="isModalVisible[1]" @close="closeModal(1)">
      <template v-slot:header>
        Attention, warning!
      </template>

      <template v-slot:body>
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
        laborum."
      </template>

      <template v-slot:footer>
        <PrimaryButton
              :action="close"
              text="Close"
          />
      </template>
    </Modal>
    
    import { modals } from '@/mixins'

    mixins: [
        modals
    ],

    created() {
        this.showModal(1)
    }
    -->
</template>

<script>
// import PrimaryButton from '@/components/Buttons/PrimaryButton.vue'

export default {
    name: 'Modal',
    // components: {
    //     PrimaryButton
    // },
    props: {
        ariaLabelledby: String, // modalTitle
        ariaDescribedby: String // modalDescription
    },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    padding: 15px 30px;
    min-width: 300px;
    max-width: 500px;
    min-height: 150px;
    text-align: center;
    position: relative;
    border-radius: $border-radius;
    // color: var(--text-font-color);
    box-shadow: 0 0 40px 4px #111118;
    // background-color: var(--dark-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-close {
        top: 10px;
        right: 10px;
        cursor: pointer;
        position: absolute;
    }

    .modal-footer>div {
        display: inline-block;
    }
}


.btn-close {
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity .5s ease;
}
</style>