import gql from 'graphql-tag'

export const gqlGeneric = gql`
    query generic($language: I18NLocaleCode) {
        header(locale: $language) {
            data {
                attributes {
                    banner
                    logo {
                        label
                        url
                        image {
                            data {
                                attributes {
                                    name
                                    url
                                }
                            }
                        }
                    }
                    menus {
                        ... on ComponentMicroLink {
                            label
                            url
                            style
                        }
                    }
                    buttons {
                        ... on ComponentMicroLink {
                            label
                            url
                            style
                        }
                    }
                }
            }
        }
        footer(locale: $language) {
            data {
                id
                attributes {
                    Section {
                        ... on ComponentMicroSection {
                            label
                            Links {
                            ... on ComponentMicroLink {
                                id
                                label
                                url
                                style
                            }
                            }
                        }
                    }
                    copyright
                }
            }
        }
    }
`
