<template>
    <div class="reassurances">
        <Title
            :title="data?.title"
            :description="data?.description"
        />
        <div class="vm">
            <div
                data-aos="fade-up"
                class="reassurance"
                v-for="reassurance in data?.items?.data"
                v-bind:key="reassurance?.id"
            >
                <img
                    v-if="reassurance?.attributes?.image?.data?.attributes?.url"
                    :src="reassurance?.attributes?.image?.data?.attributes?.url"
                    alt=""
                />
                <div class="title">
                    {{ reassurance?.attributes?.title }}
                </div>
                <div class="description">
                    {{ reassurance?.attributes?.description }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/Text/Title.vue'

export default {
    components: {
        Title
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss" scoped>
.reassurances {
    .reassurance {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 250px;

        padding: 20px 10px;
        border-radius: $border-radius;
        background-color: rgba(255, 255, 255, 0.6);

        margin: 20px;
        width: 20%;
        vertical-align: top;
        display: inline-block;

        img {
            max-width: 120px;
        }

        .title {
            font-size: 20px;
            letter-spacing: 1px;
            padding: 0 10px;
            font-weight: bold;
            margin: 20px auto 0 auto;
        }

        .description {
            font-size: 14px;
            padding: 0 10px;
            margin: 10px auto 0 auto;
        }
    }

    .reassurance:hover {
        box-shadow: var(--box-shadow-dark);
    }
}
</style>
