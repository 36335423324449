import { defineStore } from 'pinia'
import { getLanguage, setLanguage } from '@/helpers/language'

export const useStore = defineStore('store', {
    state: () => ({
        language: getLanguage()
    }),
    actions: {
        setLanguage(lang) {
            this.language = setLanguage(lang)
        }
    }
})

