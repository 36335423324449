<template>
    <div class="background-content vm">
        <video
            class="background-video"
            autoplay
            loop
            muted
            :poster="data?.imgPreload?.data?.attributes?.url"
            v-bind:key="data?.imgPreload?.data?.attributes?.url"
        >
            <source
                v-bind:key="data?.img?.data?.attributes?.url"
                :src="data?.img?.data?.attributes?.url"
                type="video/mp4"
            >
        </video>
        <div class="content vm">
            <Teaser
                class="left"
                :title="data?.title"
            />
        </div>
    </div>
</template>

<script>
import Teaser from '@/components/Text/Teaser.vue'

export default {
    components: {
        Teaser
    },
    props: {
        data: Object
    }
}
</script>

<style
    lang="scss"
    scoped
>
.large {
    min-height: 500px;
}

.normal {
    min-height: 300px;
}

.thin {
    min-height: 100px;
}

.background-content {
    height: 100vh;
    position: relative;
}


.background-video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    filter: grayscale(100%) brightness(0.5);
}

.content {
    min-height: 400px;
    position: relative;
    z-index: 3;
    /* Ensure it stays above the video */
    color: white;
    text-align: center;
    padding: 50px;
}
</style>
