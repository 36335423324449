import gql from 'graphql-tag'

export const homepage = gql`
    query homepage($language: I18NLocaleCode) {
        homepage(locale: $language) {
            data {
                id
                attributes {
                    background_image {
                        img {
                            data {
                                attributes {
                                    name
                                    url
                                }
                            }
                        }
                        title
                        description
                        buttons {
                            ... on ComponentMicroLink {
                                label
                                url
                                style
                            }
                        }
                    }
                    side_image {
                        img {
                            data {
                                attributes {
                                    name
                                    url
                                }
                            }
                        }
                        title
                        description
                        buttons {
                            ... on ComponentMicroLink {
                                label
                                url
                                style
                            }
                        }
                    }
                    reassurances {
                        title
                        description
                        items {
                            data {
                                id
                                attributes {
                                    title
                                    description
                                    image {
                                        data {
                                            attributes {
                                                name
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    testimonials {
                        title
                        description
                        items {
                            data {
                                id
                                attributes {
                                    author
                                    quote
                                    image {
                                        data {
                                            attributes {
                                                name
                                                url
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    rotatingWords {
                        titles {
                            title
                            description
                        }
                        content
                        buttons {
                            ... on ComponentMicroLink {
                                label
                                url
                                style
                            }
                        }
                    }
                }
            }
        }
    }
`
