import config from '@/data/config'
const { getLsItem, setLsItem } = require('@kznjunk/window-storages')()

export { getLanguage, setLanguage }

function getLanguage() {
    console.log('--bwop')
    const storedLang = getLsItem('language')
    const isGoodStoredLang = config.authorizedLanguage.test(storedLang)

    const navLang = navigator.language.split('-')[0]
    const isGoodNavLang = config.authorizedLanguage.test(navLang)

    if (!isGoodStoredLang && !isGoodNavLang) {
        return 'en'
    }

    if (isGoodStoredLang) {
        return storedLang.toLowerCase()
    }

    if (isGoodNavLang) {
        setLsItem('language', navLang)
        return navLang.toLowerCase()
    }

    return 'en'
}

function setLanguage(lang) {
    setLsItem('language', lang)

    return lang
}
