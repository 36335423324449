<template>
  <template v-if="featureBeta">
    <Beta />
  </template>
  <template v-else-if="featureMaintenance">
    show feature mainteannce
  </template>
  <template v-else>
    <Banner :content="header?.banner" />
    <Header :content="header" />
    <router-view />
    <Footer :content="footer" />
  </template>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useStore } from '@/stores/index'

import config from '@/data/config'
import { gqlGeneric } from '@/gqls'
import Beta from '@/views/Beta.vue'
import Banner from '@/components/Text/Banner.vue'
import Header from '@/views/Header.vue'
import Footer from '@/views/Footer.vue'

export default {
  components: {
    Beta,
    Banner,
    Header,
    Footer
  },
  data() {
    return {
      featureBeta: config.featureBeta,
      featureMaintenance: config.featureMaintenance,
      header: null,
      footer: null
    }
  },
  computed: {
    ...mapState(useStore, ['language'])
  },
  created() {
    window.setTimeout(() => {
      this.setLanguage('en')
    }, 3000)
  },
  methods: {
    ...mapActions(useStore, ['setLanguage'])
  },
  apollo: {
    content: {
      query: gqlGeneric,
      variables() {
        return {
          'language': this.language
        }
      },
      skip() {
        return this.featureBeta || this.featureMaintenance;
      },
      manual: true,
      result({ data, loading }) {
        if (!loading) {
          this.header = data?.header?.data?.attributes
          this.footer = data?.footer?.data?.attributes
        }
      },
      error(error) {
        console.log('--- xxx')
        console.log(error)
        console.log('errors', error.graphQLErrors)
      }
    }
  }
}
</script>

<style lang="scss">
#bwapp {
  text-align: center;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.banner+#header {
  margin-top: $banner-height;
}

.banner+#header.sticky {
  top: $banner-height;
}

#header+div {
  margin-top: $header-height;
}
</style>


