<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div
        id="header"
        class="vm"
        v-bind:class="{ sticky: isSticky }"
    >
        <BzioupText
            to="/"
            :src="content?.logo?.image?.data?.attributes?.url"
            alt="bwop"
        />

        <transition name="slide">
            <MenuMainMobile
                v-if="isMenuOpen"
                v-bind="content"
                :toggleMenu="toggleMenu"
            />
        </transition>
        <MenuMainDesktop v-bind="content" />

        <IconHamburger
            v-bind:class="{ open: isMenuOpen }"
            @click.prevent="toggleMenu()"
        />
    </div>
</template>

<script>
import { debounce } from '@/helpers'

import BzioupText from '@/components/Text/Bzioup.vue'
import IconHamburger from '@/components/Icons/Hamburger.vue'
import MenuMainMobile from '@/components/Menus/MainMobile.vue'
import MenuMainDesktop from '@/components/Menus/MainDesktop.vue'

export default {
    components: {
        BzioupText,
        IconHamburger,
        MenuMainMobile,
        MenuMainDesktop
    },
    data() {
        return {
            isMenuOpen: false,
            isSticky: false
        }
    },
    props: {
        content: Object
    },
    created() {
        window.addEventListener('scroll', debounce(this.consoleb, 50))
    },
    unmounted() {
        window.removeEventListener('scroll', this.consoleb)
    },
    methods: {
        toggleMenu(action) {
            this.isMenuOpen = action === 'close' ? false : !this.isMenuOpen
        },
        consoleb() {
            const threshold = 500
            const scrolld = - document.getElementById('bwapp').getBoundingClientRect().top

            if (scrolld > threshold) {
                this.isSticky = true
                console.log('--- yep')
            } else {
                this.isSticky = false
                console.log('- noppp')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
#header.sticky {
    top: 0;
    width: 100%;
    margin: auto;
    position: fixed;
    box-sizing: border-box;

    animation-name: headerSlideFromTop;
    animation-duration: 1s;
    animation-iteration-count: 1;
}

#header {
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    position: absolute;
    height: $header-height;
    justify-content: space-between;
    box-shadow: var(--box-shadow-light);
    background-color: var(--color-lightest);
}

.logo-link {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

#menu-desktop {
    display: none;
    vertical-align: middle;
}

@media (min-width: $mq-medium) {

    #menu-mobile,
    .icon-hamburger {
        display: none;
    }

    #menu-desktop {
        display: flex;
    }
}

.slide-leave-active,
.slide-enter-active {
    transition: 1s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translate(100%, 0);
}

@keyframes headerSlideFromTop {
    from {
        top: -100px;
    }

    to {
        top: 0;
    }
}
</style>
