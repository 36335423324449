<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div id="footer">
        <div class="footer-content">
            <div>
                <h4>{{ content?.Section[0]?.label }}</h4>
                <Link
                    v-for="link in content?.Section[0]?.Links"
                    v-bind:key="link.id"
                    :label="link.label"
                    :id="link.url"
                    :action="updateLang"
                />
            </div>
            <div>
                <h4>{{ content?.Section[1]?.label }}</h4>
                <FooterMenu :section="content?.Section[1]" />
            </div>
            <div>
                <h4>{{ content?.Section[2]?.label }}</h4>
                <FooterMenu :section="content?.Section[2]" />
            </div>
            <div class="animate-underline">
                <h4>{{ content?.Section[3]?.label }}</h4>
                <Facebook />
                <Twitter />
                <Linkedin />
                <Youtube />
                <Instagram />
            </div>
        </div>

        <Copyright :copyright="content?.copyright" />
    </div>
</template>

<script>
// import Logo from '@/components/Logos/Logo.vue'
import Copyright from '@/components/Text/Copyright.vue'
import FooterMenu from '@/components/Menus/Footer.vue'

import Youtube from '@/components/Icons/Social/Youtube.vue'
import Twitter from '@/components/Icons/Social/Twitter.vue'
import Linkedin from '@/components/Icons/Social/Linkedin.vue'
import Facebook from '@/components/Icons/Social/Facebook.vue'
import Instagram from '@/components/Icons/Social/Instagram.vue'

import Link from '@/components/Links/Link.vue'

export default {
    components: {
        // Logo,
        Copyright,
        FooterMenu,
        Youtube,
        Twitter,
        Linkedin,
        Facebook,
        Instagram,
        Link
    },
    props: {
        content: Object
    },
    methods: {
        updateLang(e) {
            const lang = e.currentTarget.getAttribute('data-id')

            console.log('--- A')
            console.log(lang)
        }
    }
}
</script>

<style lang="scss">
#footer {
    position: relative;
    color: var(--color-darkest);
    background-color: var(--color-lightest);
}

.footer-content {
    display: flex;
    flex-wrap: wrap;

    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: space-between;

    >div {
        flex: 100%;
        text-align: left;

        a {
            color: var(--color-dark);
        }

        a.link:hover,
        a.bold_link:hover {
            border: none;
            color: var(--color-darkest);
        }

        a.router-link-active {
            color: var(--color-darkest);
        }
    }


    >div:nth-child(1) {
        a {
            display: block;
        }
    }

    >div:nth-child(4) {
        align-items: center;
        justify-content: center;

        .social-icon {
            margin: 0 15px 0 0;
            display: inline-block;
        }

        svg {
            width: 20px;
            height: 20px;
            //fill: var(--color-darkest);
        }
    }
}

@media (min-width: $mq-small) {
    .footer-content>div {
        flex: 50%;
    }
}

@media (min-width: $mq-medium) {
    .footer-content>div {
        flex: 25%;
    }
}
</style>
