import gql from 'graphql-tag'

export const faq = gql`
  query faq($language: I18NLocaleCode) {
    faq(locale: $language) {
      data {
        id
        attributes {
          fullWidthImage {
            title
            description
            img {
              data {
                attributes {
                  name
                  url
                }
              }
            }
          }
          questions {
            titles {
              title
              description
            }
            items(sort: "order:asc") {
              data {
                id
                attributes {
                  order
                  category
                  icon {
                    data {
                      attributes {
                        name
                        url
                      }
                    }
                  }
                  questions(sort: "order:asc") {
                    data {
                      id
                      attributes {
                        question
                        answer
                      }
                    }
                  }
                }
              }
            } 
          }
          rotatingWords {
            titles {
              title
              description
            }
            content
            buttons {
              ... on ComponentMicroLink {
                label
                url
                style
              }
            }
          }
        }
      }
    }
  }
`


