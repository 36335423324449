<template>
    <div class="text-teaser">
        <h1
            v-if="title"
            v-html="title"
        />
        <h3
            v-if="description"
            v-html="description"
        />
        <div v-if="buttons">
            <Link
                v-for="button in buttons"
                :to="button.url"
                :label="button.label"
                :style="button.style"
            />
        </div>
    </div>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
    components: {
        Link
    },
    props: {
        title: String,
        description: String,
        buttons: Array
    }
}
</script>

<style lang="scss" scoped>
.text-teaser.left {
    text-align: left;
}

.text-teaser.center {
    text-align: center;
}

.text-teaser.right {
    text-align: right;
}

.text-teaser>div {
    margin-top: $margin-element;

    a+a {
        margin-top: $margin-element;
    }

    a {
        width: 50%;
        margin: auto;
        display: block;
    }
}

@media (min-width: $mq-small) {
    .text-teaser>div {
        margin-top: 0px;

        a+a {
            margin-top: 0px;
            margin-left: $margin-element;
        }

        a {
            width: auto;
            display: inline-block;
        }
    }
}
</style>
