<template>
    <div class="rotating-words">
        <h1>
            {{ data?.content?.static }}
            <span class="words">
                <span v-for="dynamic in data?.content?.dynamic">
                    <span v-if="dynamic?.space">&nbsp;</span>
                    <span v-else></span>
                    <span>{{ dynamic?.word }}</span>
                </span>
            </span>
        </h1>
        <Teaser
            class="center"
            :buttons="data?.buttons"
        />
    </div>
</template>

<script>
import Teaser from '@/components/Text/Teaser.vue'

export default {
    components: {
        Teaser
    },
    props: {
        data: Object
    }
}
</script>

<style lang="scss">
.rotating-words {
    >h1 {
        margin-left: -100px;
        margin-bottom: 50px;
    }

    >.text-teaser>div a {
        margin: auto;
    }
}

@media (min-width: $mq-medium) {
    .rotating-words {
        display: flex;
        align-items: center;
        justify-content: center;

        >h1 {
            width: 50%;
            margin-bottom: 0px;
            font-weight: normal;
        }

        >.text-teaser {
            width: 50%;
        }
    }
}

.words {
    display: inline;

    >span {
        display: inline-block;
        opacity: 0;
        font-weight: bold;
        overflow: hidden;
        position: absolute;
        animation: topToBottom 12.5s linear infinite 0s;
        -ms-animation: topToBottom 12.5s linear infinite 0s;
        -webkit-animation: topToBottom 12.5s linear infinite 0s;
    }

    >span>span:nth-child(2) {
        display: inline-block;
        border-bottom: 2px solid;
    }
}

.words>span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}

.words>span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

.words>span:nth-child(4) {
    animation-delay: 7.5s;
    -ms-animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
}

.words>span:nth-child(5) {
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
}

@-moz-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -moz-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -moz-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -webkit-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -webkit-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-ms-keyframes topToBottom {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 0;
        -ms-transform: translateY(-50px);
    }

    10% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    25% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }

    30% {
        opacity: 0;
        -ms-transform: translateY(50px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}
</style>
