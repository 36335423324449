<template>
    <div class="text-title">
        <h1 v-if="title" v-html="title" />
        <h3 v-if="description" v-html="description" />
    </div>
</template>

<script>
import Link from '@/components/Links/Link.vue'

export default {
    components: {
        Link
    },
    props: {
        title: String,
        description: String
    }
}
</script>

<style lang="scss" scoped>
.text-title {
    text-align: left;
}
</style>
