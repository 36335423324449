<template>
    <div class="copyright">
        {{ getYear(copyright) }}
    </div>
</template>

<script>
export default {
    props: {
        copyright: String
    },
    methods: {
        getYear(txt) {
            if (!txt) return

            const now = new Date()
            const YYYY = now.getFullYear()
            return txt.replace('{{YYYY}}', YYYY)
        }
    }
}
</script>

<style lang="scss">
.copyright {
    font-size: 12px;
    padding: 10px 20px;
    text-align: center;
}
</style>
