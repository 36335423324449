import gql from 'graphql-tag'

export const beta = gql`
    query beta($language: I18NLocaleCode) {
        beta(locale: $language) {
            data {
                id
                attributes {
                    imgPreload {
                        data {
                            attributes {
                                name
                                url
                            }
                        }
                    }
                    img {
                        data {
                            attributes {
                                name
                                url
                            }
                        }
                    }
                    title
                }
            }
        }
    }
`
