import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'

import Home from '../views/Home.vue'
import How from '../views/How.vue'
import Plans from '../views/Plans.vue'
import Faq from '../views/Faq.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/how',
    name: 'how',
    component: How
  },
  {
    path: '/plans',
    name: 'plans',
    component: Plans
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // createWebHashHistory(), // 
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition
    // } else if (to.hash) {
    //   return { el: to.hash, behavior: 'smooth' } // , offset: { x: 0, y: 10 } }
    // } else {
    return { top: 0 }
    // }
  }
})

export default router
