<template>
  <div class="faq-section">
    <div class="faq-category">
      <h2>{{ item?.attributes?.category }}</h2>
      <img
        v-if="item?.attributes?.icon?.data?.attributes?.url"
        :src="item?.attributes?.icon?.data?.attributes?.url"
        alt=""
      />
    </div>
    <div class="faq-questions">
      <Question
        v-for="(question, index) in item?.attributes?.questions?.data"
        v-bind:key="index"
        :index="index"
        :question="question"
        :isActive="activeQuestion === index"
        :clickQuestion="clickQuestion"
      />
    </div>
  </div>
</template>

<script>
import Question from '@/components/Text/Question.vue'

export default {
  components: {
    Question
  },
  props: {
    item: Object,
    index: Number
  },
  data: function () {
    return {
      activeQuestion: -1
    }
  },
  methods: {
    clickQuestion(index) {
      if (this.activeQuestion === index) {
        this.activeQuestion = -1
      } else {
        this.activeQuestion = index
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.faq-section {
  // width: 75%;
  margin: auto;

  .faq-category {
    text-align: left;

    h2 {
      margin-top: 5px;
      color: var(--color-text-highlight);
    }

    img {
      max-width: 50px;
    }
  }

  .faq-questions {
    text-align: left;
  }
}

@media (min-width: $mq-medium) {
  .faq-section {
    display: flex;

    .faq-category {
      flex: 1 1 0;
      margin-top: 10px;
      padding: 0 20px 0 0;
    }

    .faq-questions {
      flex: 4 1 0;
    }
  }
}
</style>    